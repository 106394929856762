<template>
  <div class="loginPage">
    <b-container class="">
      <b-row class="row">
        <b-col lg="8" offset-lg="2">
          <b-card class="formContainer my-5">
            <div>
              <h3 class="mt-2">Login</h3>

              <b-alert variant="danger" :show="error.length > 0"
              >{{ error }}
              </b-alert>

              <b-form class="mt-4">
                <b-form-group>
                  <b-input
                      v-model="username"
                      type="text"
                      id="email"
                      name="email"
                      placeholder="username"
                      required=""
                  />
                </b-form-group>
                <b-form-group>
                  <b-input
                      v-model="password"
                      type="password"
                      id="password"
                      name="password"
                      placeholder="password"
                      required=""
                  />
                </b-form-group>
                <b-button
                    type="submit"
                    class="btn loginBtn my-3"
                    @click.prevent.stop="login"
                >LOGIN
                </b-button>
              </b-form>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
export default {
  name: "Login",
  components: {},
  data() {
    return {
      username: null,
      password: null,
      error: "",
    };
  },
  mounted() {
    localStorage.removeItem("userInfo");
    this.$store.dispatch('updateUser', null);
  },
  methods: {
    login() {
      const _this = this;

      _this.error = "";

      _this._axios.post(_this.$config.api.login, {username: _this.username, password: _this.password})
          .then((res) => {

              localStorage.setItem("userInfo", JSON.stringify(res.data));
              _this.$store.dispatch('updateUser',  res.data).then(() => {
                _this.$router.push('/list').catch(() => {
                })
              });

          })
          .catch((error) => {
            console.log("---> tole", error.message);
            _this.error = error.message;
            console.log("error", error);
          });

      console.log("login", _this);
    },
  },
};
</script>
